import React from "react";

import './Footer.css';

const Footer = () => {
    return (
        <section id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mt-5 text-left">
                        <h2 className="dark-blue">Contact</h2>
                    </div>
                    <div className="col-lg-6 mt-5 text-left">
                        <h5 className="dark-blue">Phone</h5>
                        <a href="sms:+46730606590">+46 73 0606 590</a>
                        <h5 className="dark-blue mt-4">Email</h5>
                        <a href="mailto:contact@jkm.solutions">contact@jkm.solutions</a>
                        <h5 className="dark-blue mt-4">Address</h5>
                        <p>
                            JKM Solutions AB<br/>
                            c/o Kevin Rasmusson<br/>
                            Surbrunnsgatan 4<br/>
                            114 27 Stockholm<br/>
                            Sweden
                        </p>
                        <div className="socials">
                            <a href="https://linkedin.com/company/jkmsolutions" target="_BLANK" rel="noreferrer">
                                <img src="/img/icons/linkedin.png" alt="LinkedIn icon" height="50px" width="50px"/>
                            </a>
                            <a href="https://discordapp.com/users/237596991050219521" target="_BLANK" rel="noreferrer">
                                <img src="/img/icons/discord.png" alt="Discord icon" height="50px" width="50px"/>
                            </a>
                            <a href="https://steamcommunity.com/profiles/76561198166295458" target="_BLANK" rel="noreferrer">
                                <img src="/img/icons/steam-blue.png" alt="Steam icon" height="50px" width="50px"/>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 copyright-text">
                        Copyright &copy; 2021-2024, JKM Solutions AB, org <a href="https://www.allabolag.se/5593374951/jkm-solutions-ab" target="_BLANK" rel="noreferrer">559337-4951</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;